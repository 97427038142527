
import React from "react"
import Layout from "../components/layout"
import SEO from '../components/seo';

export default () => (
  <Layout>
    <SEO title="Athens - Europe | Kiwi & Cacahuate" description="5 week trip to Europe" url="https://kiwicacahuate.com/greece/" image="https://kiwicacahuate.com/greece/greece.jpg" imageWidth="4000" imageHeight="2668" />

    <div className="">
      <div className="story">
        <div className="photoset-section">

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/athens/athens.webp, /athens/athens.webp 2x" />
                <source srcSet="/athens/athens.jpg, /athens/athens.jpg 2x" />
                <img src="/athens/athens.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/athens/thumbnail/025.webp, /athens/thumbnail/025-2x.webp 2x" />
                <source srcSet="/athens/thumbnail/025.jpg, /athens/thumbnail/025-2x.jpg 2x" />
                <img src="/athens/thumbnail/025.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/athens/thumbnail/001.webp, /athens/thumbnail/001-2x.webp 2x" />
                <source srcSet="/athens/thumbnail/001.jpg, /athens/thumbnail/001-2x.jpg 2x" />
                <img src="/athens/thumbnail/001.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/athens/thumbnail/002.webp, /athens/thumbnail/002-2x.webp 2x" />
                <source srcSet="/athens/thumbnail/002.jpg, /athens/thumbnail/002-2x.jpg 2x" />
                <img src="/athens/thumbnail/002.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/athens/thumbnail/003.webp, /athens/thumbnail/003-2x.webp 2x" />
                <source srcSet="/athens/thumbnail/003.jpg, /athens/thumbnail/003-2x.jpg 2x" />
                <img src="/athens/thumbnail/003.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/athens/thumbnail/005.webp, /athens/thumbnail/005-2x.webp 2x" />
                <source srcSet="/athens/thumbnail/005.jpg, /athens/thumbnail/005-2x.jpg 2x" />
                <img src="/athens/thumbnail/005.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/athens/thumbnail/006.webp, /athens/thumbnail/006-2x.webp 2x" />
                <source srcSet="/athens/thumbnail/006.jpg, /athens/thumbnail/006-2x.jpg 2x" />
                <img src="/athens/thumbnail/006.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/athens/thumbnail/008.webp, /athens/thumbnail/008-2x.webp 2x" />
                <source srcSet="/athens/thumbnail/008.jpg, /athens/thumbnail/008-2x.jpg 2x" />
                <img src="/athens/thumbnail/008.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/athens/thumbnail/009.webp, /athens/thumbnail/009-2x.webp 2x" />
                <source srcSet="/athens/thumbnail/009.jpg, /athens/thumbnail/009-2x.jpg 2x" />
                <img src="/athens/thumbnail/009.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/athens/thumbnail/011.webp, /athens/thumbnail/011-2x.webp 2x" />
                <source srcSet="/athens/thumbnail/011.jpg, /athens/thumbnail/011-2x.jpg 2x" />
                <img src="/athens/thumbnail/011.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/athens/thumbnail/012.webp, /athens/thumbnail/012-2x.webp 2x" />
                <source srcSet="/athens/thumbnail/012.jpg, /athens/thumbnail/012-2x.jpg 2x" />
                <img src="/athens/thumbnail/012.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/athens/thumbnail/013.webp, /athens/thumbnail/013-2x.webp 2x" />
                <source srcSet="/athens/thumbnail/013.jpg, /athens/thumbnail/013-2x.jpg 2x" />
                <img src="/athens/thumbnail/013.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/athens/thumbnail/016.webp, /athens/thumbnail/016-2x.webp 2x" />
                <source srcSet="/athens/thumbnail/016.jpg, /athens/thumbnail/016-2x.jpg 2x" />
                <img src="/athens/thumbnail/016.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/athens/thumbnail/015.webp, /athens/thumbnail/015-2x.webp 2x" />
                <source srcSet="/athens/thumbnail/015.jpg, /athens/thumbnail/015-2x.jpg 2x" />
                <img src="/athens/thumbnail/015.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/athens/thumbnail/017.webp, /athens/thumbnail/017-2x.webp 2x" />
                <source srcSet="/athens/thumbnail/017.jpg, /athens/thumbnail/017-2x.jpg 2x" />
                <img src="/athens/thumbnail/017.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/athens/thumbnail/018.webp, /athens/thumbnail/018-2x.webp 2x" />
                <source srcSet="/athens/thumbnail/018.jpg, /athens/thumbnail/018-2x.jpg 2x" />
                <img src="/athens/thumbnail/018.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/athens/thumbnail/019.webp, /athens/thumbnail/019-2x.webp 2x" />
                <source srcSet="/athens/thumbnail/019.jpg, /athens/thumbnail/019-2x.jpg 2x" />
                <img src="/athens/thumbnail/019.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/athens/thumbnail/023.webp, /athens/thumbnail/023-2x.webp 2x" />
                <source srcSet="/athens/thumbnail/023.jpg, /athens/thumbnail/023-2x.jpg 2x" />
                <img src="/athens/thumbnail/023.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/athens/thumbnail/020.webp, /athens/thumbnail/020-2x.webp 2x" />
                <source srcSet="/athens/thumbnail/020.jpg, /athens/thumbnail/020-2x.jpg 2x" />
                <img src="/athens/thumbnail/020.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/athens/thumbnail/022.webp, /athens/thumbnail/022-2x.webp 2x" />
                <source srcSet="/athens/thumbnail/022.jpg, /athens/thumbnail/022-2x.jpg 2x" />
                <img src="/athens/thumbnail/022.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/athens/thumbnail/024.webp, /athens/thumbnail/024-2x.webp 2x" />
                <source srcSet="/athens/thumbnail/024.jpg, /athens/thumbnail/024-2x.jpg 2x" />
                <img src="/athens/thumbnail/024.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/athens/thumbnail/030.webp, /athens/thumbnail/030-2x.webp 2x" />
                <source srcSet="/athens/thumbnail/030.jpg, /athens/thumbnail/030-2x.jpg 2x" />
                <img src="/athens/thumbnail/030.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/athens/thumbnail/026.webp, /athens/thumbnail/026-2x.webp 2x" />
                <source srcSet="/athens/thumbnail/026.jpg, /athens/thumbnail/026-2x.jpg 2x" />
                <img src="/athens/thumbnail/026.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/athens/thumbnail/028.webp, /athens/thumbnail/028-2x.webp 2x" />
                <source srcSet="/athens/thumbnail/028.jpg, /athens/thumbnail/028-2x.jpg 2x" />
                <img src="/athens/thumbnail/028.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/athens/thumbnail/031.webp, /athens/thumbnail/031-2x.webp 2x" />
                <source srcSet="/athens/thumbnail/031.jpg, /athens/thumbnail/031-2x.jpg 2x" />
                <img src="/athens/thumbnail/031.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/athens/thumbnail/032.webp, /athens/thumbnail/032-2x.webp 2x" />
                <source srcSet="/athens/thumbnail/032.jpg, /athens/thumbnail/032-2x.jpg 2x" />
                <img src="/athens/thumbnail/032.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/athens/thumbnail/035.webp, /athens/thumbnail/035-2x.webp 2x" />
                <source srcSet="/athens/thumbnail/035.jpg, /athens/thumbnail/035-2x.jpg 2x" />
                <img src="/athens/thumbnail/035.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/athens/thumbnail/033.webp, /athens/thumbnail/033-2x.webp 2x" />
                <source srcSet="/athens/thumbnail/033.jpg, /athens/thumbnail/033-2x.jpg 2x" />
                <img src="/athens/thumbnail/033.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/athens/thumbnail/034.webp, /athens/thumbnail/034-2x.webp 2x" />
                <source srcSet="/athens/thumbnail/034.jpg, /athens/thumbnail/034-2x.jpg 2x" />
                <img src="/athens/thumbnail/034.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/athens/thumbnail/036.webp, /athens/thumbnail/036-2x.webp 2x" />
                <source srcSet="/athens/thumbnail/036.jpg, /athens/thumbnail/036-2x.jpg 2x" />
                <img src="/athens/thumbnail/036.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/athens/thumbnail/037.webp, /athens/thumbnail/037-2x.webp 2x" />
                <source srcSet="/athens/thumbnail/037.jpg, /athens/thumbnail/037-2x.jpg 2x" />
                <img src="/athens/thumbnail/037.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/athens/thumbnail/038.webp, /athens/thumbnail/038-2x.webp 2x" />
                <source srcSet="/athens/thumbnail/038.jpg, /athens/thumbnail/038-2x.jpg 2x" />
                <img src="/athens/thumbnail/038.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
)

